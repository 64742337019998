import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["output"]

  connect() {
    this.update()
  }

  update() {
    if (!event || !event.currentTarget) return

    this.outputTarget.textContent = event.currentTarget.value
  }
}
