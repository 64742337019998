import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["field"]

  connect() {
    const randomString = Math.random().toString(16).substr(2, 6)

    this.fieldTarget.value = randomString
    this.element.action = `${this.element.action}?via=${randomString}`
  }
}
