import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["element"]
  static classes = ["highlight"]

  connect() {
    this.elementTargets.forEach((target) => {
      this.#observer().observe(target)
    })
  }

  // private

  #observer() {
    return new IntersectionObserver((elements) => {
      elements.forEach(element => {
        if (!element.target.dataset) return

        let highlightElement = document.querySelector(`[data-highlight='${element.target?.dataset?.highlight}']`)

        element.isIntersecting ? highlightElement.classList.add(...this.highlightClasses) : highlightElement.classList.remove(...this.highlightClasses)
      })
    },
      {
        threshold: 0.75
      }
    )
  }
}
