import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static values = {
    append: String,
    newSignup: Boolean
  }

  connect() {
    let value = ""
    let via = this.urlParams().get("via")

    if (!!via && this.appendValue) {
      value = `${this.appendValue}?via=${via}`
    } else if (!!via) {
      value = via
    } else {
      value = this.appendValue
    }

    if (this.newSignupValue) {
      this.element.value = value
    } else {
      this.element.textContent = value
    }
  }

  // private

  urlParams() {
    return new URLSearchParams(this.queryString)
  }

  get queryString() {
    return window.location.search
  }
}
