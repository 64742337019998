import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["element"]
  static classes = ["highlight"]

  connect() {
    this.#observer().observe(this.element)
  }

  // private

  #observer() {
    return new IntersectionObserver((elements) => {
      elements.forEach(element => {
        let ratio = Math.round(element.intersectionRatio * 12)

        element.target.querySelectorAll("span").forEach((element, index) => {
          if (ratio > index) {
            element.classList.add("!text-black")
          } else {
            element.classList.remove("!text-black")
          }
        })
      })
    },
      {
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
        rootMargin: "-10%",
      }
    )
  }
}
