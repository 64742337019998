import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = [ "element" ]
  static classes = [ "toggle" ]
  static values  = {
    open: Boolean
  }

  toggle() {
    this.openValue = !this.openValue
  }

  hide(event) {
    if (this.element.contains(event.target) === false && this.openValue) {
      this.openValue = false
    }
  }

  disconnect() {
    this.openValue = false
  }

  // private

  openValueChanged() {
    if (this.openValue) {
      this.sourceElement.classList.remove(this.toggleClass)
    } else {
      this.sourceElement.classList.add(this.toggleClass)
    }
  }

  get sourceElement() {
    return (this.hasElementTarget ? this.elementTarget : this.element)
  }
}
